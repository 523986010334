import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react'
import { SelectChangeEvent } from '@mui/material/Select'
import { useSystemValues } from '@/contexts/ValueContext'
import { isValid, parse } from 'postcode'
import { toast } from 'sonner'
import { STEP, ToastError } from '@qtc-repo/common/constants'
import { isObjectFilled, scrollToTop } from '@qtc-repo/common/utils'
import dynamic from 'next/dynamic'

const TextInput = dynamic(() => import('@qtc-repo/ui/components/TextInput/TextInput'), {})
const AddressCard = dynamic(() => import('@qtc-repo/ui/components/AddressCard/AddressCard'), {})
const PostcodeInput = dynamic(() => import('@qtc-repo/ui/components/TextInput/PostcodeInput'), {})
const LinkText = dynamic(() => import('@qtc-repo/ui/components/LinkText/LinkText'), {})
const MuiSelect = dynamic(() => import('@qtc-repo/ui/components/Select/MuiSelect'), {})
const InputHelper = dynamic(() => import('@qtc-repo/ui/components/Form/InputHelper'), {})

const Address = ({ offscreen = false }: { offscreen?: boolean }) => {
  const {
    showPulse,
    setShowPulse,
    addressList,
    setAddressList,
    userData: data,
    handleFormChange,
    firstEvents,
    setFirstEvents,
    setUserData,
    setManualAddr,
    manualAddr,
    useManualAddr: useManualAddress,
    setUseManualAddr,
    selectedAddress,
    setSelectedAddress,
    setUseAutoComplete,
    setStepValid,
  } = useSystemValues()

  const useManualAddr = offscreen || useManualAddress
  const [postCodeFocus, setPostCodeFocus] = useState(false)
  const [fetchingAddresses, setFetchingAddresses] = useState(false)
  const manualAddrCleanup = () => {
    setFirstEvents(prev => ({
      ...prev,
      addressLine1: true,
      addressLine2: true,
      townOrCity: true,
      postCode: true,
    }))
    setManualAddr({
      addressLine1: '',
      addressLine2: '',
      townOrCity: '',
      postCode: '',
    })
    setUserData(prev => ({ ...prev, postCode: '', address: '' }))
    setShowPulse(false)
    currentAddressListPostCode!.current = ''
    localStorage.setItem('usr_data', JSON.stringify({ ...data, postCode: '', address: '' }))
  }

  const clearAddresses = () => {
    manualAddrCleanup()
    setSelectedAddress('')
    setAddressList([])
    setFirstEvents(prev => ({ ...prev, address: true, postCode: true }))
    currentAddressListPostCode.current = ''
    setUserData({ ...data, postCode: '', address: '' })
  }
  // keep track of postcode whose address is currently being shown so we don't refetch unneccessarily
  const currentAddressListPostCode = useRef<string>(parse(data.postCode)?.postcode || '')
  const postCode = useManualAddr ? manualAddr.postCode : data.postCode
  const step_valid =
    !!selectedAddress ||
    (!!postCode &&
      isValid(postCode) &&
      (useManualAddr ? isObjectFilled(manualAddr, ['postCode', 'addressLine1', 'townOrCity']) : !!data.address))
  useEffect(() => {
    setStepValid(prev => ({ ...prev, [STEP.ADDRESS]: step_valid }))
    // eslint-disable-next-line
  }, [step_valid])
  useEffect(() => {
    if (useManualAddr) {
      const { addressLine1, townOrCity } = manualAddr
      if (isValid(data.postCode)) {
        toast.dismiss(ToastError.POSTCODE)
      }
      if (townOrCity) {
        toast.dismiss(ToastError.TOWN)
      }
      if (addressLine1) {
        toast.dismiss(ToastError.ADDRESS_LINE_1)
      }
    } else {
      if (addressList?.length > 0) {
        toast.dismiss(ToastError.ADDRESS_PULSE)
        if (selectedAddress) {
          toast.dismiss(ToastError.SELECTED_ADDRESS)
        }
      }
    }
  }, [manualAddr, useManualAddr, data.postCode, addressList, selectedAddress])
  useEffect(() => {
    toast.dismiss(ToastError.POSTCODE)
    toast.dismiss(ToastError.ADDRESS_PULSE)
    toast.dismiss(ToastError.SELECTED_ADDRESS)
    toast.dismiss(ToastError.ADDRESS_LINE_1)
    toast.dismiss(ToastError.TOWN)
  }, [useManualAddr])
  useEffect(() => {
    if (isValid(data.postCode)) {
      toast.dismiss(ToastError.POSTCODE)
    } else {
      toast.dismiss(ToastError.SELECTED_ADDRESS)
    }
  }, [data.postCode])
  // handles  setting the values of autocompleted inputs from homescreen
  useEffect(() => {
    const { addressLine1, addressLine2, townOrCity, postCode } = manualAddr
    if (postCode) {
      setUserData(prev => ({ ...prev, postCode }))
    }
    if (!addressLine1 || !townOrCity) {
      return
    }
    const address = `${addressLine1}, ${addressLine2 ? addressLine2 + ', ' : ''}${townOrCity}`
    setSelectedAddress(address)
    setUserData(prev => ({ ...prev, address }))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    scrollToTop()
    currentAddressListPostCode.current = data.postCode
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data.postCode && isValid(data.postCode) && !addressList.length) {
      setShowPulse(true)
    }
    //eslint-disable-next-line
  }, [addressList.length, data.postCode])

  // Turn off pulse if addresses have been fetched
  useEffect(() => {
    if (addressList.length) {
      setShowPulse(false)
    }
    //eslint-disable-next-line
  }, [addressList])

  useEffect(() => {
    setSelectedAddress(data.address)
    //eslint-disable-next-line
  }, [data.address])

  const isAddressValid = addressList.some(
    addr => data.address === addr.suggestion.substr(0, addr.suggestion.lastIndexOf(','))
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target
    switch (name) {
      case 'postCode':
        value = value.substr(0, 8).replace('.', '')
        if (isValid(value.trim())) {
          value = parse(value.trim()).postcode!
        }
        if (value !== currentAddressListPostCode.current) {
          const show = !!value && isValid(value)
          setShowPulse(show)
        }
        break
    }
    if (offscreen) {
      setUseAutoComplete(true)
    }
    handleFormChange(name, value)
  }
  /**
   * stop pulsing when address has been selected
   */
  useEffect(() => {
    if (data.address && data.postCode === currentAddressListPostCode.current) {
      setShowPulse(false)
    }
    //eslint-disable-next-line
  }, [data.address])

  useEffect(() => {
    if (!data.postCode) {
      setAddressList([])
      setFirstEvents(prev => ({ ...prev, address: true }))
      setShowPulse(false)
    }
    //eslint-disable-next-line
  }, [data.postCode])

  const handleMUISelectChange = (e: SelectChangeEvent) => {
    const value = e.target.value
    handleFormChange(e.target.name, value)
  }

  // /**
  //  * discard the prefilled address if it is invalid
  //  */
  // useEffect(() => {
  //   if (
  //     data.address &&
  //     addressList.length &&
  //     !isAddressValid &&
  //     !useManualAddr
  //   ) {
  //     handleFormChange("address", "");
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data.address, isAddressValid, addressList]);
  const searchAddressByPostcode = (e: string) => {
    if (!e || !isValid(e)) {
      return
    }
    const endpoint = `https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=ak_ku4e95aqGky1uIIQZMefHVykARiTn&q=${e}`
    setFetchingAddresses(true)
    fetch(endpoint, { method: 'GET', redirect: 'follow' })
      .then(response => response.json())
      .then(res => {
        if (res.result && res.result.hits) {
          setAddressList(res.result.hits)
          currentAddressListPostCode.current = e
        } else {
          setAddressList([])
        }
      })
      .catch(error => console.log('error', error.message))
      .finally(() => {
        setFetchingAddresses(false)
      })
  }

  useEffect(() => {
    if (data && data.postCode) {
      if (!addressList.length) {
        searchAddressByPostcode(data.postCode)
      }
    }
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="grid gap-5 sm:grid-cols-2">
        <>
          {!selectedAddress && !useManualAddr && (
            <PostcodeInput
              onSearch={() => {
                searchAddressByPostcode(data.postCode)
                if (showPulse) {
                  // pulse should disappear after addresses have been fetched
                  setShowPulse(false)
                }
              }}
              error={!postCodeFocus && !firstEvents.postCode && !isValid(data.postCode || '')}
              success={!postCodeFocus && isValid(data.postCode || '')}
              showPulse={showPulse}
              label={'Postcode'}
              value={isValid(data.postCode) ? (parse(data.postCode).postcode as string) : data.postCode}
              onChange={handleInputChange}
              onFocus={() => setPostCodeFocus(true)}
              onBlur={() => setPostCodeFocus(false)}
              loading={fetchingAddresses}
              placeholder="e.g. CH5 3UZ"
              helper={
                <InputHelper noIcon>
                  <span className={'text-base font-medium'}>
                    or{' '}
                    <LinkText
                      text={'enter your address manually instead'}
                      onClick={() => {
                        setUseManualAddr(true)
                        setFirstEvents(prev => ({ ...prev, postCode: true }))
                      }}
                    />
                  </span>
                </InputHelper>
              }
            />
          )}
          {!useManualAddr && !selectedAddress && data.postCode && addressList?.length > 0 ? (
            <>
              <div className={'sm:col-span-2'}>
                <MuiSelect
                  label={'Select your address'}
                  id={'address'}
                  value={data.address}
                  onChange={e => handleMUISelectChange(e)}
                  name="address"
                  defaultOption={`${addressList.length} address${addressList.length > 1 ? 'es' : ''} found`}
                  options={addressList.map((item: any) => ({
                    label: item.suggestion.substr(0, item.suggestion.lastIndexOf(',')),
                    value: item.suggestion.substr(0, item.suggestion.lastIndexOf(',')),
                  }))}
                  success={isAddressValid}
                  error={!firstEvents.address && !isAddressValid}
                />

                {!firstEvents.address && !data.address && (
                  <InputHelper text="Please select your address from the list" error />
                )}
              </div>
            </>
          ) : null}
          {selectedAddress && (
            <AddressCard onClear={clearAddresses} address={selectedAddress} postCode={data.postCode} />
          )}
          <div
            className={'grid gap-5 sm:col-span-2 sm:grid-cols-2'}
            style={{
              ...(!(useManualAddr && !selectedAddress) && {
                maxHeight: 0,
                overflow: 'hidden',
                position: 'absolute',
                visibility: 'hidden',
              }),
            }}
          >
            <ManualAddress offscreen={offscreen} cleanup={manualAddrCleanup} />
          </div>
        </>
      </div>
    </>
  )
}

const ManualAddress = ({ cleanup, offscreen }: { cleanup: () => void; offscreen: boolean }) => {
  const [inputFocused, setInputFocused] = useState({
    addressLine1: false,
    addressLine2: false,
    townOrCity: false,
    postCode: false,
  })

  const {
    firstEvents,
    manualAddr,
    setManualAddr,
    setFirstEvents,
    useManualAddr,
    setUseManualAddr,
    setUseAutoComplete,
    setSelectedAddress,
    setUserData,
    selectedAddress,
  } = useSystemValues()

  useEffect(() => {
    if (useManualAddr && !selectedAddress) return
    const { addressLine1, addressLine2, townOrCity, postCode } = manualAddr
    if (postCode && isValid(postCode) && addressLine1 && townOrCity) {
      let address = `${addressLine1}, ${addressLine2 ? addressLine2 + ', ' : ''}${townOrCity}`
      setSelectedAddress(address)
      setUserData(prev => ({ ...prev, address, postCode }))
    }
    // eslint-disable-next-line
  }, [manualAddr])
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { name: key, value } = e.target
    if (key === 'postCode') {
      value = value.substr(0, 8).replace('.', '')
      if (isValid(value.trim())) {
        value = parse(value.trim()).postcode!
      }
    }
    setManualAddr(prev => ({
      ...prev,
      [key]: value,
    }))

    setFirstEvents(prev => ({
      ...prev,
      [key]: false,
    }))
    if (offscreen) {
      setUseAutoComplete(true)
    }
  }

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    const { name } = e.target
    setInputFocused(prev => ({ ...prev, [name]: true }))
  }
  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { name } = e.target
    setInputFocused(prev => ({ ...prev, [name]: false }))
  }
  const idPrefix = offscreen ? 'offscreen-' : ''
  return (
    <>
      <div
        className={`form-group sm:col-span-2 ${
          firstEvents.addressLine1 || inputFocused.addressLine1 ? '' : manualAddr.addressLine1 ? 'success' : 'error'
        }`}
      >
        <TextInput
          label={'Address line 1'}
          type="text"
          id={`${idPrefix}addressLine1`}
          name={`addressLine1`}
          autoComplete="address-line1"
          value={manualAddr.addressLine1}
          onChange={e => {
            handleInputChange(e)
          }}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </div>
      <div
        className={`form-group sm:col-span-2 ${
          firstEvents.addressLine2 || inputFocused.addressLine2 ? '' : manualAddr.addressLine2 ? 'success' : ''
        }`}
      >
        <TextInput
          label={'Address line 2'}
          id={`${idPrefix}addressLine2`}
          name={`addressLine2`}
          autoComplete="address-line2"
          value={manualAddr.addressLine2 || ''}
          onChange={e => handleInputChange(e)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </div>
      <div
        className={`form-group sm:col-span-2 ${
          firstEvents.townOrCity || inputFocused.townOrCity ? '' : manualAddr.townOrCity ? 'success' : 'error'
        }`}
      >
        <TextInput
          label={'Town or city'}
          id={`${idPrefix}townOrCity`}
          name={`townOrCity`}
          autoComplete="address-level2"
          value={manualAddr.townOrCity}
          onChange={e => handleInputChange(e)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </div>
      <div
        className={`form-group sm:col-span-2 ${
          firstEvents.postCode || inputFocused.postCode
            ? ''
            : manualAddr.postCode && isValid(manualAddr.postCode)
              ? 'success'
              : 'error'
        }`}
      >
        <TextInput
          label={'Postcode'}
          id={`${idPrefix}postCode`}
          name={`postCode`}
          autoComplete="postal-code"
          placeholder="e.g. CH5 3UZ"
          value={manualAddr.postCode}
          onChange={e => handleInputChange(e)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <InputHelper noIcon>
          <span className={'text-base'}>
            <LinkText
              text={' Try the lookup again'}
              onClick={() => {
                cleanup()
                setUseManualAddr(false)
              }}
            />
          </span>
        </InputHelper>
      </div>
    </>
  )
}
export default Address
